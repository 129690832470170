import React, { useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  LocationSelect,
  MobileScreen,
} from 'components'
import { headerBg, logo, logoTitle, mobileLogo, viewMenu } from 'images'
import { dispatch } from 'store'

const orderingUrl =
  process.env.REACT_APP_ENVIRONMENT === 'dev'
    ? 'https://demo.codefusion.tech/restaurants/ebisu/locations/ebisuLocation1'
    : 'https://order.codefusion.tech/restaurants/8GFwb8UTNOQ2rbtqc9kk/locations/Y7GXtSFgqD6z5v9Jpd8q'

const images = []

export default () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <MobileScreen>
        <CFView column center mt="10px">
          <CFImage w="90%" src={mobileLogo} alt="Sehmi Sushi Logo" />
          <CFView
            mt="10px"
            black
            xBold
            h3
            center
            br="40px"
            bc="black"
            bw="3.5px"
            pt="2px"
            pb="5px"
            ph="15px"
          >
            Port Alberni
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center w="100%" zIndex={98} h="50px" bg="rgb(0,0,0,.8)">
          <CFView row justifyBetween alignCenter w="100%">
            <CFView row center>
              <CFImage
                ml="50px"
                mt="105px"
                w="550px"
                src={logo}
                alt="Sehmi Sushi Logo"
                zIndex={98}
              />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
      <ModalGateway>
        {showModal ? (
          <Modal onClose={() => setShowModal(false)}>
            <Carousel views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}
